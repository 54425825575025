import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: "#35007D",
      padding: theme.spacing(1),
      border: `1px solid #35007D`,
      borderRadius: theme.spacing(3),
      textAlign: "center",
      cursor: "pointer",
      fontSize: "0.875rem",
    },
    selected: {
      backgroundColor: "#35007D",
      color: "white",
    },
  })
);
