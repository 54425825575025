import React, { ReactElement } from "react";

import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Divider,
  Avatar,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import Logo from "../../../assets/images/pede-pronto-logo-large.png";
import { useStyles } from "./header.styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../configs/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth/auth";

interface Props {
  handleDrawerToggle: VoidFunction;
}

export default function Header(props: Props): ReactElement {
  const [user] = useAuthState(auth);
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event) => {
    !anchorEl && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <AppBar className={classes.contentContainer}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img className={classes.logo} src={Logo} alt="Pede Pronto" />
          {user && (
            <Box className={classes.profileContainer}>
              <Avatar className={classes.avatar} id="user-avatar">
                LS
              </Avatar>
              <Typography className={classes.userName}>{user.email}</Typography>
              <Button
                id="menu-button"
                className={classes.openMenuButton}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <KeyboardArrowDown />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "menu-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose;
                      navigate("/settings");
                    }}
                  >
                    Conta
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose;
                      navigate("/orders/logout");
                      signOut();
                    }}
                  >
                    Sair do sistema de pedidos
                  </MenuItem>
                </Menu>
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Divider />
    </Box>
  );
}
