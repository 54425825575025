import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: 4,
      display: "flex",
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
    },
    warning: {
      background: "#FFFBCC",
    },
    success: {
      background: "#E1FCE9",
    },
    error: {
      background: "#FDE0D6",
    },
    notification: {
      background: "#CEF4FF",
    },
    title: {
      fontWeight: 600,
    },
    description: {
      fontSize: "0.875rem",
    },
  })
);
