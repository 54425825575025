import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontWeight: "bold",
    },
    historicTable: {
      marginTop: theme.spacing(2),
      border: "1px solid #ECE6F0",
      borderRadius: "4px",
      backgroundColor: "#FFFFFF",
    },
    marginBottomTable: {
      borderBottom: "1px solid #ECE6F0",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    label: {
      fontSize: "12px",
    },
    value: {
      fontWeight: 400,
      fontSize: "14px",
    },
    statusValue: {
      fontWeight: 600,
      fontSize: "12px",
    },
    marginLeft: {
      marginLeft: theme.spacing(5),
    },
    paginationPosition: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
