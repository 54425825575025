import { forwardRef, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import {
  Box,
  ButtonBase,
  InputBase,
  InputBaseProps,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./input.styles";
import eyeShow from "../../../../assets/svgs/eye-show.svg";
import eyeHide from "../../../../assets/svgs/eye-hide.svg";

export interface InputProps extends InputBaseProps {
  label: string;
  type: string;
  errors: any;
  revealPassword?: boolean;
}

function Input(
  { label, type, revealPassword, errors, ...rest }: InputProps,
  ref
) {
  const classes = useStyles();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const toogleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const getErrorMessage = (field) => {
    return (
      <ErrorMessage
        errors={errors}
        name={field}
        render={({ message }) => (
          <Typography color="error">{message}</Typography>
        )}
      />
    );
  };

  return (
    <Box marginBottom={4} position="relative">
      <label className={classes.label}> {label} </label>
      <InputBase
        className={`${classes.input} ${
          errors[rest.name] && classes.inputError
        }`}
        {...rest}
        type={isShowPassword ? "text" : type}
        ref={ref}
      />
      {getErrorMessage(rest.name)}
      {revealPassword && (
        <ButtonBase onClick={toogleShowPassword} className={classes.eyeIcon}>
          <img src={isShowPassword ? eyeHide : eyeShow} alt="exibir senha" />
        </ButtonBase>
      )}
    </Box>
  );
}

export default forwardRef(Input);
