interface getDataByDeliveryStatusProps {
  step: number;
  description: string;
}

export default (
  deliveryStatus: string
): getDataByDeliveryStatusProps | null => {
  switch (deliveryStatus) {
    case "PENDING":
      return {
        step: 1,
        description: "Um entregador foi solicitado para realizar a entrega",
      };
    case "ACCEPTED":
      return {
        step: 2,
        description: "A entrega foi aceita pelo entregador",
      };
    case "ARRIVED_AT_MERCHANT":
      return {
        step: 3,
        description: "O entregador está aguardando o pedido",
      };
    case "DELIVERY_ONGOING":
      return {
        step: 4,
        description: "A entrega do pedido está em andamento",
      };
    case "ORDER_DELIVERED":
      return {
        step: 5,
        description: "O pedido foi entregue",
      };
    case "CANCELLED":
      return {
        step: 0,
        description: "A entrega foi cancelada",
      };
    default:
      return {
        step: 0,
        description: "",
      };
  }
};
