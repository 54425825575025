import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    btnSubmit: {
      backgroundColor: "#6833AD !important",
      color: "#fff !important",
      display: "block",
      margin: "0 auto",
      textTransform: "capitalize",
      padding: "8px 42px",
    },
  })
);
