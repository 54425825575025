import { Box, CssBaseline } from "@material-ui/core";
import { useState } from "react";
import Header from "../../common/componentes/header/header";
import Sidebar from "../../common/componentes/sidebar/sidebar";
import { useStyles } from "./template.styles";

const Wrapper = (Component) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} />

      <Box className={classes.contentContainer}>
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          isSuperUser={true}
        />
        <Box component="main" p={2} width="100%">
          <Component />
        </Box>
      </Box>
    </>
  );
};

const withTemplate = (InputComponent) => {
  return () => Wrapper(InputComponent);
};

export default withTemplate;
