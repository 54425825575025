import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: "8.125rem",
      fontSize: "0.875rem",
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(0.75, 1.75),
      color: theme.palette.neutral.light,
      fontWeight: 600,
      textTransform: "inherit",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);
