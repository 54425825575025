import ThemeHandler from "./common/componentes/theme-handler/theme-handler";
import Routes from "./routes";
import { AuthProvider } from "./common/hooks/auth/auth";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { Notifications } from "./common/componentes/notifications/notifications";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeHandler>
          <AuthProvider>
            <Routes />
            <Notifications />
          </AuthProvider>
        </ThemeHandler>
      </PersistGate>
    </Provider>
  );
}
