import { Box, makeStyles, Typography } from "@material-ui/core";
import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../store/store";
import { currentOrderSelector } from "../slice/orders.slice";
import { useReactToPrint } from "react-to-print";
import getSubTakewayDescription from "../../../common/helpers/get-sub-takeway-description";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      maxWidth: "300px",
      margin: "10px auto",
      background: "#f2f2f2",
      color: "#000",
    },
    hr: {
      border: "none",
      borderTop: "1px dashed #000",
      marginTop: 8,
      marginBottom: 8,
    },
  },
  text_center: {
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    textTransform: "uppercase",
  },
  text_small: {
    fontSize: 10,
    textTransform: "uppercase",
  },
  logo: {
    fontSize: "18px",
    marginBottom: 0,
    lineHeight: "10px",
  },
  caption: {
    fontSize: "12px",
  },
  number: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  strong: {
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

function OrdersPrint() {
  const classes = useStyles();
  const order = useAppSelector(currentOrderSelector);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const formatCurrency = useCallback(
    (currency: string, amount: number) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: currency,
      }).format(amount);
    },
    [order]
  );

  const formatDateTime = useCallback(
    (date: string) => {
      const parseDate = new Date(date);
      const month = parseDate.toLocaleDateString("pt-BR");
      const hours = parseDate.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `${month} às ${hours}`;
    },
    [order]
  );

  useEffect(() => {
    handlePrint();
  }, []);

  const getAddress = (deliveryAddress) => {
    if (!deliveryAddress) {
      return "-";
    }

    return `${deliveryAddress.street},  Nº ${deliveryAddress.number}, ${deliveryAddress.city}/${deliveryAddress.state} - ${deliveryAddress.postalCode}`;
  };

  const cupom = order?.payments?.methods?.find(
    (item) => item.method === "COUPON"
  );

  return (
    <div ref={componentRef}>
      <Box
        bgcolor="#fff"
        padding={2}
        borderRadius={6}
        boxShadow="2px 2px 8px #ccc"
      >
        <Box display="flex" mt="15px">
          <Box flex={1}>
            <Typography className={classes.logo}>Pede Pronto</Typography>
            <Typography className={classes.caption}>
              {order.merchant.name}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.number}>
              #{order.displayId}
            </Typography>
          </Box>
        </Box>
        <hr />
        <Box>
          <Typography className={classes.strong}>
            {order.type === "TAKEOUT"
              ? getSubTakewayDescription(order.subType)
              : "ENTREGA"}
          </Typography>
          <Typography className={classes.text_center}>
            {formatDateTime(order.createdAt).toLocaleString()}
          </Typography>
        </Box>
        <hr />
        <Box>
          <Typography className={classes.strong}>
            {order.customer.name}
          </Typography>

          {order.delivery && order.eventType !== "CREATED" && (
            <Typography
              className={classes.text_small}
              align="center"
              style={{
                lineHeight: "12px",
              }}
            >
              {getAddress(order?.delivery?.deliveryAddress)}
              <br />
              {order?.delivery?.deliveryAddress?.complement
                ? order?.delivery?.deliveryAddress?.complement
                : ""}
            </Typography>
          )}
        </Box>
        <hr />
        {order.items.map((item) => (
          <Box display="flex" key={item.id}>
            <Typography
              className={classes.text}
              style={{
                width: "20px",
                display: "inline-block",
              }}
            >
              {item.quantity}
            </Typography>
            <Typography
              className={classes.text}
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              {item.name}
            </Typography>
            <Typography
              className={classes.text}
              style={{
                display: "inline-block",
                marginLeft: "20px",
              }}
            >
              {formatCurrency(item.totalPrice.currency, item.totalPrice.value)}
            </Typography>
          </Box>
        ))}

        {cupom && order?.discounts?.[0]?.targetId && (
          <>
            <hr />
            <Box>
              <Typography className={`${classes.text_center} ${classes.text}`}>
                CUPOM:{"  "}
                {order?.discounts?.[0]?.targetId}
              </Typography>
            </Box>
          </>
        )}

        <hr />
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.text}>FORMA DE PAGAMENTO</Typography>
          <Typography className={classes.text}>
            {order.payments?.methods[0].method === "CREDIT" &&
              "Cartão de crédito"}
            {order.payments?.methods[0].method === "CREDIT_DEBIT" &&
              "Cartão de débito"}
            {order.payments?.methods[0].method === "OTHER" &&
              "Pagamento Offline"}
          </Typography>
        </Box>

        {(order.total.otherFees.value !== 0 ||
          order.total.discount.value !== 0) && (
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Typography className={classes.text}>Subtotal</Typography>
            <Typography className={classes.text}>
              {formatCurrency(
                order.total.itemsPrice.currency,
                order.total.itemsPrice.value
              )}
            </Typography>
          </Box>
        )}

        {order.total.discount.value !== 0 && (
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.text}>
              DESCONTO PEDE PRONTO
            </Typography>
            <Typography className={classes.text}>
              {"- "}
              {formatCurrency(
                order.total.discount.currency,
                order.total.discount.value
              )}
            </Typography>
          </Box>
        )}

        {(order.total.otherFees.value !== 0 || order.type === "DELIVERY") && (
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.text}>Taxa de entrega:</Typography>
            <Typography className={classes.text}>
              {order.total.otherFees.value !== 0
                ? formatCurrency(
                    order.total.otherFees.currency,
                    order.total.otherFees.value
                  )
                : "Grátis"}
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.text}>
            {order.payments?.methods[0].type === "PREPAID"
              ? "VALOR PAGO"
              : "VALOR A COBRAR"}
          </Typography>
          <Typography className={classes.text}>
            {formatCurrency(
              order.total.orderAmount.currency,
              order.total.orderAmount.value
            )}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default OrdersPrint;
