import { Box, Button, Paper, Typography } from "@material-ui/core";
import SepartorPoint from "../../../../common/componentes/separator-point/separator-point";
import { useStyles } from "./order-card.styles";
import fileIcon from "../../../../assets/svgs/file-plus.svg";
import campaign from "../../../../assets/svgs/campaign.svg";
import ButtonOrder from "../button-order/button-order";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  alreadyCanceled,
  confirmedNotifySound,
  notify,
  OrderItem,
  setCurrentOrder,
  startTimers,
} from "../../slice/orders.slice";
import { useDispatch } from "react-redux";
import { format, intervalToDuration } from "date-fns";
import {
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useDispatchOrderMutation,
  useFinishOrderMutation,
  useReadyForPickupOrderMutation,
} from "../../slice/orders.api";
import { CancelationCodes } from "../../types/orders";
import { AnyAction } from "@reduxjs/toolkit";
import LogisticSteps from "../logistic-steps/logistic-steps";
import getDataByDeliveryStatus from "../../../../common/helpers/get-data-by-delivery-status";
import getSubTakewayDescription from "../../../../common/helpers/get-sub-takeway-description";

interface OrderCardProps {
  order: OrderItem;
  orderRefetch: () => void;
}

export default function OrderCard({ order, orderRefetch }: OrderCardProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cancelOrderMutation] = useCancelOrderMutation();
  const [confirmOrderMutation] = useConfirmOrderMutation();
  const [dispatchOrderMutation] = useDispatchOrderMutation();
  const [finishOrderMutation] = useFinishOrderMutation();
  const [readyForPickupOrderMutation] = useReadyForPickupOrderMutation();

  const formatTime = useCallback(
    (date: string) => {
      const parseDate = new Date(date);
      return format(parseDate, "HH:mm");
    },
    [order]
  );

  const formatStopWatch = useCallback(
    (stopWatch: number) => {
      const duration = intervalToDuration({ start: 0, end: stopWatch * 1000 });

      return `0${duration.minutes}:${duration.seconds < 10 ? "0" : ""}${
        duration.seconds
      }`;
    },
    [order]
  );

  const formatMoney = useCallback(
    (money: number) => {
      return `R$ ${money.toFixed(2).replace(".", ",")}`;
    },
    [order]
  );

  useEffect(() => {
    if (order.eventType === "CREATED" && !order.stopWatchRunning) {
      dispatch(startTimers(order) as unknown as AnyAction);
    }

    if (order.eventType === "CONFIRMED") {
      dispatch(confirmedNotifySound(order) as any);
    }
  }, []);

  useEffect(() => {
    if (
      !order.alreadyCanceled &&
      order.stopWatch &&
      order.stopWatch <= 1 &&
      order.eventType === "CREATED"
    ) {
      cancelOrderMutation({
        orderId: order.id,
        reason: "Pedido não confirmado pelo restaurante.",
        code: CancelationCodes.INTERNAL_DIFFICULTIES_OF_THE_RESTAURANT,
      });
      dispatch(
        notify({
          type: "warning",
          title: "Atenção!",
          description: `O pedido #${order.displayId} foi cancelado`,
        }) as any
      );
      clearInterval(order.interval);
      dispatch(
        alreadyCanceled({
          id: order.id,
        })
      );
    }
  }, [order.stopWatch]);

  const handleChangeOrderStatus = useCallback(async () => {
    if (order.eventType === "CREATED") {
      await confirmOrderMutation({
        orderId: order.id,
        displayId: order.displayId,
      });
      clearInterval(order.interval);
      orderRefetch();
    }

    if (order.eventType === "CONFIRMED") {
      await dispatchOrderMutation({ orderId: order.id });
      orderRefetch();
    }

    if (order.eventType === "READY_FOR_PICKUP") {
      await readyForPickupOrderMutation({ orderId: order.id });
      orderRefetch();
    }

    if (order.eventType === "DISPATCHED") {
      await finishOrderMutation({ orderId: order.id, type: "CONCLUDED" });
      orderRefetch();
    }
  }, [order]);

  return (
    <Paper
      elevation={2}
      id={`order-card-${order.id}`}
      className={classes.container}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        position="relative"
      >
        <Box flex={1}>
          <Box display="flex">
            <Typography className={classes.textInfo} color="textPrimary">
              Pedido #{order.displayId}
            </Typography>
            <SepartorPoint />
            <Typography className={`${classes.textInfo} ${classes.textOrigin}`}>
              {order.type === "DELIVERY"
                ? "Delivery"
                : getSubTakewayDescription(order.subType)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography className={classes.textInfo}>
              {order.customer.name}
            </Typography>
            <SepartorPoint />
            <Typography className={classes.textInfo}>
              {formatMoney(order.total.orderAmount.value)}
            </Typography>
            <SepartorPoint />
            <Typography className={classes.textInfo}>
              {formatTime(order.createdAt)}
            </Typography>
          </Box>

          {order.isFullService && (
            <Box className={classes.logistic}>
              <LogisticSteps
                step={getDataByDeliveryStatus(order.deliveryStatus)?.step}
              />
              <Typography className={classes.logistic_description}>
                {getDataByDeliveryStatus(order.deliveryStatus)?.description}
              </Typography>
            </Box>
          )}
        </Box>
        {order.eventType === "CREATED" && !order.stopWatch && (
          <Typography className={classes.badge}>Novo pedido</Typography>
        )}
        {order.eventType === "CREATED" && order.stopWatch && (
          <Typography className={classes.badgeInterval}>
            Faltam {`${formatStopWatch(order.stopWatch)}`}
          </Typography>
        )}

        {order.id === "delivered" && (
          <Box display="flex" alignItems="flex-start">
            <Button
              id={`order-card-button-info-${order.id}`}
              startIcon={<img src={campaign} alt="icone de alarme" />}
              className={classes.btnRecall}
            >
              <Typography className={classes.textRecall}>Rechamar</Typography>
            </Button>
          </Box>
        )}
      </Box>

      <Box className={classes.body}>
        <Box>
          <Typography className={classes.textInfo} color="textPrimary">
            Itens
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {order.flattenItems.slice(0, 2).map((item, index) => (
              <Typography key={index} className={classes.textInfo}>
                {item.quantity}x {item.name}
              </Typography>
            ))}
          </Box>
          {order.flattenItems.length > 2 && (
            <Box display="flex" alignItems="end">
              <Typography className={classes.textInfo}>
                + {order.flattenItems.slice(2).length} itens
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Button
          id={`order-card-button-info-${order.id}`}
          startIcon={<img src={fileIcon} alt="icone mais informações" />}
          className={classes.btnInfo}
          onClick={() => {
            navigate("/orders/details");
            dispatch(setCurrentOrder(order));
          }}
        >
          Mais informações
        </Button>
        <ButtonOrder
          eventType={order.eventType}
          orderId={order.id}
          onClick={handleChangeOrderStatus}
        />
      </Box>
    </Paper>
  );
}
