import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: "1px solid #D1D1DE",
      width: "100%",
      borderRadius: 5,
      marginTop: 8,
      color: "#434559",
      "& input": {
        boxSizing: "border-box",
        padding: 16,
        height: 48,
      },
    },
    label: {
      color: "#434559",
    },
    inputError: {
      border: "1px solid #FF8B63",
    },
    eyeIcon: {
      position: "absolute",
      borderRadius: "50%",
      right: 13,
      top: 40,
    },
  })
);
