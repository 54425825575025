import { Box, Typography } from "@material-ui/core";
import { useCallback } from "react";
import alertIcon from "../../../assets/svgs/exclamation-triangle.svg";
import SuccessIcon from "../../../assets/svgs/check.svg";
import InfoIcon from "../../../assets/svgs/info.svg";
import TimesIcon from "../../../assets/svgs/times.svg";
import { useStyles } from "./alert-message.styles";

interface AlertMessageProps {
  title: string;
  description: string;
  type: "success" | "notification" | "warning" | "error";
}

export default function AlertMessage({
  title,
  description,
  type,
}: AlertMessageProps) {
  const classes = useStyles();

  const getConfig = useCallback(() => {
    if (type === "notification") {
      return {
        class: classes.notification,
        icon: InfoIcon,
        label: "aviso",
      };
    }
    if (type === "warning") {
      return {
        class: classes.warning,
        icon: alertIcon,
        label: "atenção",
      };
    }
    if (type === "error") {
      return {
        class: classes.error,
        icon: TimesIcon,
        label: "atenção",
      };
    }

    return {
      class: classes.success,
      icon: SuccessIcon,
      label: "sucesso",
    };
  }, [type]);

  const config = getConfig();

  return (
    <Box className={`${classes.container} ${config.class}`}>
      <img src={config.icon} alt={`ícone de ${config.label}`} />
      <Box ml={2}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
}
