import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, Navigate } from "react-router-dom";
import ProgressPage from "../common/componentes/progress-page/progress-page";
import { auth } from "../configs/firebase";

function PublicRoute({ children }) {
  const location = useLocation();
  const [user, loading] = useAuthState(auth);

  const isAuthenticated = !!user && !loading;

  if (loading) {
    return <ProgressPage />;
  }

  if (isAuthenticated) {
    return <Navigate to="/orders" state={{ from: location }} />;
  }

  return children;
}

export default PublicRoute;
