import React from "react";

import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useStyles } from "./breadcrumbs-handler.styles";

interface BreadcrumbsHandlerProps {
  items: {
    label: string;
    path?: string;
  }[];
}

export default function BreadcrumbsHandler({ items }: BreadcrumbsHandlerProps) {
  const classes = useStyles();

  return (
    <Breadcrumbs separator="›">
      <Link to="/">
        <Typography className={classes.textColor} variant="overline">
          Pede Pronto Admin
        </Typography>
      </Link>

      {items.map((item) =>
        item.path ? (
          <Link to={item.path} key={item.label}>
            <Typography className={classes.textColor} variant="overline">
              {item.label}
            </Typography>
          </Link>
        ) : (
          <Typography
            key={item.label}
            className={classes.selectedBreadcrumb}
            variant="overline"
          >
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}
