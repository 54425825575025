import getSubTakewayDescription from "./get-sub-takeway-description";

const styles = {
  box: "font-family: sans-serif;",
  flex: "display: flex; ",
  logo: "font-size: 18px; margin-bottom: 0; line-height: 10px;",
  caption: "font-size: 12px;",
  number: "font-size: 22px; font-weight: bold;",
  hr: "border: 0; border-top: 1px dashed #000; margin: 8px 0;",
  strong: "font-weight: bold; font-size: 16px; text-align: center;",
  text_center: "text-align: center;",
  text: "font-size: 12px;text-transform: uppercase;margin: 8px 0;",
  text_small: "font-size: 10px;text-transform: uppercase;",
  flex_1: "flex: 1;",
};

const formatCurrency = (currency: string, amount: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: currency,
  }).format(amount);
};

const formatDateTime = (date: string) => {
  const parseDate = new Date(date);
  const month = parseDate.toLocaleDateString("pt-BR");
  const hours = parseDate.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${month} às ${hours}`;
};

const getAddress = (deliveryAddress) => {
  if (!deliveryAddress) {
    return "-";
  }

  return `${deliveryAddress.street},  Nº ${deliveryAddress.number}, ${deliveryAddress.city}/${deliveryAddress.state} - ${deliveryAddress.postalCode}`;
};

const getCupom = (order) =>
  order?.payments?.methods?.find((item) => item.method === "COUPON");

export default function LayoutPrint(order) {
  const html = `
    <div style="${styles.box}">


        <div style="${styles.flex}">
            <div style="${styles.flex_1}">
                <p style="${styles.logo}">Pede Pronto</p>
                <p style="${styles.caption}">
                    ${order.merchant.name}
                </p>
            </div>
            <div>
                <p style="${styles.number}">
                    #${order.displayId}
                </p>
            </div>
        </div>
        <hr style="${styles.hr}"/>
        <div>
            <p style="${styles.strong}">
                ${
                  order.type === "TAKEOUT"
                    ? getSubTakewayDescription(order.subType)
                    : "ENTREGA"
                }
            </p>
            <p style="${styles.text_center}">
                ${formatDateTime(order.createdAt).toLocaleString()}
            </p>
        </div>
        <hr style="${styles.hr}"/>
        <div>
          <p style="${styles.strong}">
            ${order.customer.name}
          </p>

          ${
            order.delivery &&
            order.eventType !== "CREATED" &&
            `
            <p
              style="${styles.text_small}; line-height: 12px;"
              align="center"
            >
              ${getAddress(order?.delivery?.deliveryAddress)}
              <br />
              ${
                order?.delivery?.deliveryAddress?.complement
                  ? order?.delivery?.deliveryAddress?.complement
                  : ""
              }
            </p>
          `
          }
        </div>
    <hr style="${styles.hr}"/>
    ${order.items
      .map(
        (item) => `
        <div style="${styles.flex}">
          <p style="${styles.text}; width: 20px; display: inline-block;">
            ${item.quantity}
          </p>
          <p style="${styles.text}; display: flex; flex: 1">
            ${item.name}
          </p>
          <p style="${styles.text}; display: inline-block; margin-left: 20px;">
            ${formatCurrency(item.totalPrice.currency, item.totalPrice.value)}
          </p>
        </div>
      `
      )
      .join("")}

    ${
      getCupom(order) && order?.discounts?.[0]?.targetId
        ? `
        <>
          <hr />
          <div>
            <p style={${styles.text_center} ${styles.text}}>
              CUPOM:{"  "}
              ${order?.discounts?.[0]?.targetId}
            </p>
          </div>
        </>
      `
        : ""
    }

      <hr style="${styles.hr}"/>
      <div style="${styles.flex}; justify-content: space-between">
        <p style="${styles.text}">FORMA DE PAGAMENTO</p>
        <p style="${styles.text}">
          ${
            order.payments?.methods[0].method === "CREDIT"
              ? "Cartão de crédito"
              : ""
          }
          ${
            order.payments?.methods[0].method === "CREDIT_DEBIT"
              ? "Cartão de débito"
              : ""
          }
          ${
            order.payments?.methods[0].method === "OTHER"
              ? "Pagamento Offline"
              : ""
          }
        </p>
      </div>

      ${
        order.total.otherFees.value !== 0 || order.total.discount.value !== 0
          ? `
        <div style="${
          styles.flex
        }; justify-content: space-between;margin-top: 8px;">
          <p style="${styles.text}">Subtotal</p>
          <p style="${styles.text}">
            ${formatCurrency(
              order.total.itemsPrice.currency,
              order.total.itemsPrice.value
            )}
          </p>
        </div>
      `
          : ""
      }

      ${
        order.total.discount.value !== 0
          ? `
        <div style="${styles.flex}; justify-content: space-between;">
          <p style="${styles.text}">
            DESCONTO PEDE PRONTO
          </p>
          <p style="${styles.text}">
            ${"- "}${formatCurrency(
              order.total.discount.currency,
              order.total.discount.value
            )}
          </p>
        </div>
      `
          : ""
      }

      ${
        order.total.otherFees.value !== 0 || order.type === "DELIVERY"
          ? `
        <div style="${styles.flex}; justify-content: space-between;">
          <p style="${styles.text}">Taxa de entrega:</p>
          <p style="${styles.text}">
            ${
              order.total.otherFees.value !== 0
                ? formatCurrency(
                    order.total.otherFees.currency,
                    order.total.otherFees.value
                  )
                : "Grátis"
            }
          </p>
        </div>
      `
          : ""
      }

      <div style="${styles.flex}; justify-content: space-between;">
        <p style="${styles.text}">
          ${
            order.payments?.methods[0].type === "PREPAID"
              ? "VALOR PAGO"
              : "VALOR A COBRAR"
          }
        </p>
        <p style="${styles.text}">
          ${formatCurrency(
            order.total.orderAmount.currency,
            order.total.orderAmount.value
          )}
        </p>
      </div>

    </div>
  `;

  return [
    {
      type: "text",
      value: html,
      style: {},
    },
  ];
}
