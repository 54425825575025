import { flatten } from "lodash";
import { OrderItem } from "../../features/orders/slice/orders.slice";
import { IEventType, OrderResponse } from "../../features/orders/types/orders";

export const normalizeOrderItem = (
  order: OrderResponse,
  eventType: IEventType
): OrderItem => {
  const parsedDate = `${order.createdAt}Z`;

  const flattenItems = order.items.map((item) => {
    let orderItem = Object.assign({}, item);
    return [orderItem];
  });

  return {
    ...order,
    interval: null,
    stopWatch: null,
    createdAt: parsedDate,
    alreadyNotified: true,
    alreadyCanceled: false,
    eventType,
    flattenItems: flatten(flattenItems),
  };
};
