import { makeStyles, Theme, createStyles } from "@material-ui/core";

const getPercent = (step: number) => {
  if (step === 1) return "0%";
  if (step === 2) return "25%";
  if (step === 3) return "50%";
  if (step === 4) return "75%";
  if (step === 5) return "100%";
};

const getMarginLeftByStep = (step: number) => {
  if (step === 1) return "12px";
  if (step === 2) return "8px";
  if (step === 3) return "6px";
  if (step === 4) return "2px";
  if (step === 5) return "0px";
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "@keyframes enter": {
        "0%": {
          transform: "scale(0)",
          opacity: 0.1,
        },
        "50%": {
          transform: "scale(1)",
          opacity: 1,
        },
        "100%": {
          transform: "scale(0)",
          opacity: 0.1,
        },
      },
      "@keyframes width": {
        "0%": {
          width: "0%",
        },
        "100%": {
          width: "23%",
        },
      },
    },
    container: (props: { step: number }) => ({
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      "&::before": {
        content: "''",
        width: "100%",
        borderTop: "1px dashed #C1C1C6",
        position: "absolute",
        top: "50%",
        left: 0,
      },
      "&::after": {
        content: "''",
        width: getPercent(props.step),
        height: "1px",
        background: "#6833AD",
        position: "absolute",
        top: "50%",
        left: 0,
      },
    }),
    step: (props) => ({
      width: "6px",
      height: "6px",
      background: "#E6E6E8",
      borderRadius: "50%",
      zIndex: 2,
      "&.active": {
        background: "#6833AD",
      },
      "&.actual": {
        width: "12px",
        height: "12px",
        background: "#ffffff",
        border: "1px solid #6833AD",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "&::before": {
          content: "''",
          width: "30px",
          borderTop: "1px solid #6833AD",
          position: "absolute",
          left: getPercent(props.step),
          animation: `width 2s ease-in-out infinite`,
          marginLeft: getMarginLeftByStep(props.step),
          display: props.step > 4 ? "none" : "block",
        },

        "&::after": {
          content: "''",
          width: "6px",
          height: "6px",
          background: "#6833AD",
          borderRadius: "50%",
          display: "block",
          animation: props.step <= 4 ? `enter 2s ease-in-out infinite` : "",
        },
      },
    }),
  })
);
