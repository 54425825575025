import * as yup from "yup";

const loginValidateSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Esse campo é obrigatório")
      .email("Insira um endereço de email válido"),
    password: yup
      .string()
      .required("Esse campo é obrigatório")
      .min(6, "A senha deve ter pelo menos 6 caracteres"),
  })
  .required();

export default loginValidateSchema;
