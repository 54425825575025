import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    filter: {},
    selectfilter: {
      width: "20%",
      backgroundColor: "#FFFFFF",
      border: 0,
      outline: 0,
      paddingRight: 5,
      paddingLeft: 15,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
    },
  })
);
