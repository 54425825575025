import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: "initial",
      fontWeight: 600,
    },
  })
);
