import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(12),
      position: "absolute",
    },
    icon: {
      position: "absolute",
      cursor: "pointer",
      width: "1.5rem",
      height: "1.5rem",
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);
