export interface OrdersItems {
  id: number;
  name: string;
  quantity: number;
}

export enum CancelationCodes {
  SYSTEMIC_ISSUES = "SYSTEMIC_ISSUES",
  DUPLICATE_APPLICATION = "DUPLICATE_APPLICATION",
  UNAVAILABLE_ITEM = "UNAVAILABLE_ITEM",
  RESTAURANT_WITHOUT_DELIVERY_MAN = "RESTAURANT_WITHOUT_DELIVERY_MAN",
  OUTDATED_MENU = "OUTDATED_MENU",
  ORDER_OUTSIDE_THE_DELIVERY_AREA = "ORDER_OUTSIDE_THE_DELIVERY_AREA",
  BLOCKED_CUSTOMER = "BLOCKED_CUSTOMER",
  OUTSIDE_DELIVERY_HOURS = "OUTSIDE_DELIVERY_HOURS",
  INTERNAL_DIFFICULTIES_OF_THE_RESTAURANT = "INTERNAL_DIFFICULTIES_OF_THE_RESTAURANT",
  RISK_AREA = "RISK_AREA",
}

export type IEventType =
  | "CREATED"
  | "CONFIRMED"
  | "DISPATCHED"
  | "READY_FOR_PICKUP"
  | "PICKUP_AREA_ASSIGNED"
  | "CONCLUDED"
  | "CANCELLATION_REQUESTED"
  | "CANCELLATION_REQUEST_DENIED"
  | "CANCELLED"
  | "ORDER_CANCELLATION_REQUEST";

export type IOrderEvent = [
  {
    marketplace: string;
    eventType: IEventType;
    orderId: string;
    orderUrl: string;
    createdAt: string;
    companyId: number;
    eventId: string;
  }
];

export interface IItem {
  id: string;
  index: number;
  name: string;
  externalCode: string;
  unit: string;
  quantity: number;
  unitPrice: { value: number; currency: string };
  totalPrice: { value: number; currency: string };
  options: {
    id: string;
    index: number;
    name: string;
    externalCode: string;
    unit: string;
    quantity: number;
    unitPrice: { value: number; currency: string };
    totalPrice: { value: number; currency: string };
  }[];
}

export interface OrderResponse {
  id: string;
  type: string;
  displayId: string;
  createdAt: string;
  orderTiming: string;
  preparationStartDateTime: string;
  merchant: {
    id: string;
    name: string;
    document: string;
  };
  items: [
    {
      id: string;
      index: number;
      name: string;
      externalCode: string;
      unit: string;
      quantity: number;
      unitPrice: { value: number; currency: string };
      totalPrice: { value: number; currency: string };
      options: {
        id: string;
        index: number;
        name: string;
        externalCode: string;
        unit: string;
        quantity: number;
        unitPrice: { value: number; currency: string };
        totalPrice: { value: number; currency: string };
      }[];
    }
  ];
  otherFees: [
    {
      name: string;
      type: string;
      receivedBy: string;
      receiverDocument: string;
      price: { value: number; currency: string };
      observation: string;
    }
  ];
  discounts: [
    {
      amount: { value: number; currency: string };
      target: string;
      description: string;
      targetId: string;
      sponsorshipValues: [
        {
          name: string;
          amount: { value: number; currency: string };
        },
        { name: string; amount: { value: number; currency: string } }
      ];
    },
    {
      amount: { value: number; currency: string };
      target: string;
      description: string;
      sponsorshipValues: [
        {
          name: string;
          amount: { value: number; currency: string };
        }
      ];
    },
    {
      amount: { value: number; currency: string };
      target: string;
      description: string;
      sponsorshipValues: [
        {
          name: string;
          amount: { value: number; currency: string };
        },
        { name: string; amount: { value: number; currency: string } }
      ];
    }
  ];
  total: {
    otherFees: { value: number; currency: string };
    discount: { value: number; currency: string };
    orderAmount: { value: number; currency: string };
    itemsPrice: { value: number; currency: string };
  };
  payments: {
    prepaid: number;
    pending: number;
    methods: [
      {
        value: number;
        currency: string;
        type: "PREPAID" | "PENDING";
        method: string;
      }
    ];
  };
  customer: {
    id: string;
    externalId: string;
    phone: { number: string; extension: any };
    documentNumber: string;
    name: string;
    ordersCountOnMerchant: number;
  };
  events: IOrderEvent;
  delivery?: {
    deliveredBy: string;
    deliveryAddress: {
      country: string;
      state: string;
      city: string;
      neighborhood: string;
      streetName: string;
      streetNumber: string;
      complement: string;
      postalCode: string;
      coordinates: { latitude: number; longitude: number };
      formattedAddress: string;
    };
    estimatedDeliveryDateTime: string;
  };
  extraInfo?: string;
  subType?: string;
  isFullService: boolean;
  deliveryStatus: string;
}
