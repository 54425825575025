import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PuplicRoute";

import Logout from "../features/auth/logout/logout";
import Login from "../features/auth/login/login";
import Orders from "../features/orders/pages/orders";
import HistoricOrders from "../features/historic-orders/pages/historic-orders";
import OrdersDetails from "../features/orders/pages/orders-details";
import OrdersPrint from "../features/orders/pages/orders-print";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/details"
          element={
            <PrivateRoute>
              <OrdersDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/print"
          element={
            <PrivateRoute>
              <OrdersPrint />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/orders/logout" element={<Logout />} />
        <Route
          path="/orders/history"
          element={
            <PrivateRoute>
              <HistoricOrders />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
