import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1100,
      marginTop: theme.spacing(3),
    },
    content: {
      padding: theme.spacing(2, 0),
    },
    contentPaddingLeft: {
      paddingLeft: theme.spacing(7),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
    label: {
      fontSize: "0.875rem",
    },
    value: {
      fontWeight: 600,
      fontSize: "0.875rem",
    },
    borderBottom: {
      borderBottom: "1px solid #E2E1E3",
    },
    borderRight: {
      borderRight: "1px solid #E2E1E3",
      [theme.breakpoints.down("sm")]: {
        borderRight: "none",
      },
    },
    paddingLeft: {
      paddingLeft: theme.spacing(3),
    },
    marginTop: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
    },
    marginLeft: {
      marginLeft: theme.spacing(3),
    },
    marginTop2: {
      marginTop: theme.spacing(2),
    },
  })
);
