import { Button, Typography } from "@material-ui/core";
import backIcon from "../../../assets/svgs/arrow_back.svg";
import { useStyles } from "./back-button.styles";

export default function BackButton({ onClick }) {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      startIcon={<img src={backIcon} alt="ícone voltar" />}
    >
      <Typography className={classes.title}>Voltar</Typography>
    </Button>
  );
}
