import React, { ReactElement, useEffect, useState } from "react";

import {
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  Drawer,
  Hidden,
  ListItemText,
  Typography,
  Box,
  Collapse,
} from "@material-ui/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sidebarItem from "./sidebar-items";

import Logo from "../../../assets/images/pede-pronto-logo-large.png";
import { useStyles } from "./sidebar.styles";
import bellSchoolIcon from "../../../assets/svgs/bell-school.svg";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: VoidFunction;
  isSuperUser: boolean;
}

export default function SideBar(props: Props): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();

  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const sidebarContent = (
    <>
      <List className={classes.listContainer}>
        {sidebarItem.map((menu) => (
          <ListItem
            key={menu.name}
            classes={{
              root: classes.listItem,
              selected: classes.listItemSelected,
            }}
            selected={pathname.includes(menu.path)}
          >
            <Link className={classes.link} to={menu.path ? menu.path : ""}>
              <ListItemIcon className={classes.icon}>{menu.icon}</ListItemIcon>
              <ListItemText>
                <Typography className={classes.sidebarItems}>
                  {menu.name}
                </Typography>
              </ListItemText>
            </Link>
            {menu?.subMenu &&
              menu?.subMenu.map((subMenu) => (
                <Link
                  key={subMenu.name}
                  className={classes.link}
                  to={subMenu.path}
                >
                  <ListItemText>{subMenu.name}</ListItemText>
                </Link>
              ))}
          </ListItem>
        ))}

        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.listItemSelected,
          }}
          button
          onClick={handleClick}
          selected={true}
        >
          <ListItemIcon className={classes.icon}>
            <img src={bellSchoolIcon} alt="ícone pedidos" width={26} />
          </ListItemIcon>
          <ListItemText>
            <Typography color="primary" className={classes.sidebarItems}>
              Pedidos
            </Typography>
          </ListItemText>
          {open ? (
            <ExpandLess color="primary" />
          ) : (
            <ExpandMore color="primary" />
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => navigate("/orders")}>
              <Typography> Gestor</Typography>
            </ListItem>
            <ListItem button onClick={() => navigate("/orders/history")}>
              <Typography>Histórico</Typography>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );

  return (
    <nav className={classes.container}>
      <Hidden implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <>
            <img className={classes.logo} src={Logo} alt="Pede Pronto" />
            {sidebarContent}
          </>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {sidebarContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}
