import { Fade, Snackbar } from "@material-ui/core";
import { notificationListSelector } from "../../../features/orders/slice/orders.slice";
import { useAppSelector } from "../../../store/store";
import AlertMessage from "../alert-message/alert-message";

export function Notifications() {
  const notificationList = useAppSelector(notificationListSelector);

  return (
    <>
      {notificationList?.map((item, index) => (
        <Fade in key={item.id} timeout={1000}>
          <Snackbar
            id={item.id}
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            style={{ top: 100 * index }}
          >
            <AlertMessage {...item} />
          </Snackbar>
        </Fade>
      ))}
    </>
  );
}
