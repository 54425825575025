import { Box, Typography } from "@material-ui/core";
import offline from "../../../../assets/svgs/offline.svg";
import online from "../../../../assets/svgs/online.svg";
import SepartorPoint from "../../../../common/componentes/separator-point/separator-point";
import { useStyles } from "./status-restaurant.styles";

interface StatusRestaurantProps {
  name: string;
  status: boolean;
}

export default function StatusRestaurant({
  name,
  status,
}: StatusRestaurantProps) {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.container}>
      <img src={status ? online : offline} alt="status" />
      <Typography className={classes.status}>
        {status ? "Online" : "Offline"}
      </Typography>
      <SepartorPoint />
      <Typography className={classes.name}>{name}</Typography>
    </Box>
  );
}
