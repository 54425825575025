import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCMohC3T6WsY9T3d87HZIF12fNgW3mOK1c",
  authDomain: "pp-gestor-20.firebaseapp.com",
  projectId: "pp-gestor-20",
  storageBucket: "pp-gestor-20.appspot.com",
  messagingSenderId: "306823142847",
  appId: "1:306823142847:web:30789305e84466f076950a",
  measurementId: "G-MS5PNN168C",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
