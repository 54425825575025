import { IconButton } from "@material-ui/core";
import React from "react";
import { useStyles } from "./cancelation-code-label.styles";

interface Props {
  title: string;
  isSelected: boolean;
  onClick: VoidFunction;
}

export default function CancelationCodeLabel({
  title,
  isSelected,
  onClick,
}: Props) {
  const classes = useStyles();

  return (
    <IconButton
      onClick={onClick}
      className={`${classes.container} ${isSelected && classes.selected}`}
    >
      {title}
    </IconButton>
  );
}
