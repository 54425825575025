import { Box, Grid } from "@material-ui/core";
import PageTitle from "../../../common/componentes/page-title/page-title";
import StatusRestaurant from "../components/status-restaurant/status-restaurant";
import GridHeader from "../components/grid-header/grid-header";
import EmptyMessage from "../components/empty-message/empty-message";
import withTemplate from "../../../styles/template/template";
import OrderCard from "../components/order-card/order-card";
import { useGetOrdersQuery, useGetMerchantQuery } from "../slice/orders.api";
import { useAppSelector } from "../../../store/store";
import {
  deliveredOrdersSelector,
  preparingOrdersSelector,
  waitingOrdersSelector,
} from "../slice/orders.slice";
import { config } from "../../../configs/config";

function Home() {
  const orderRequest = useGetOrdersQuery(null, {
    pollingInterval: config.pollingInterval,
  });
  const merchantRequest = useGetMerchantQuery();
  const waitingOrders = useAppSelector(waitingOrdersSelector);
  const preparingOrders = useAppSelector(preparingOrdersSelector);
  const deliveredOrders = useAppSelector(deliveredOrdersSelector);

  return (
    <Box component="main" p={2} width="100%">
      <PageTitle title="Pedidos" />
      {!merchantRequest.isLoading && (
        <StatusRestaurant
          name={merchantRequest?.data?.name}
          status={merchantRequest?.data?.status === "AVAILABLE"}
        />
      )}
      <Box mt={8} maxWidth="1104px">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <GridHeader
              title="Para aceitar"
              borderColor="#DE4A82"
              count={waitingOrders.length}
            />

            {waitingOrders.length > 0 &&
              waitingOrders.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  orderRefetch={orderRequest.refetch}
                />
              ))}

            {!waitingOrders.length && (
              <EmptyMessage message="Os pedidos que chegarem aparecerão aqui." />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <GridHeader
              title="Preparando"
              borderColor="#0C96FF"
              count={preparingOrders.length}
            />

            {preparingOrders.length > 0 &&
              preparingOrders.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  orderRefetch={orderRequest.refetch}
                />
              ))}

            {!preparingOrders.length && (
              <EmptyMessage message="Os pedidos que a cozinha estiver preparando aparecerão aqui." />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <GridHeader
              title="Entrega"
              borderColor="#60D6B1"
              count={deliveredOrders.length}
            />

            {deliveredOrders.length > 0 &&
              deliveredOrders.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  orderRefetch={orderRequest.refetch}
                />
              ))}

            {!deliveredOrders.length && (
              <EmptyMessage message="Os pedidos prontos para entrega aparecerão aqui." />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default withTemplate(Home);
