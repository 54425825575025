import {
  SimplePaletteColorOptions,
  ThemeOptions,
  useTheme,
} from "@material-ui/core";
import { CustomSimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";
import Color from "color";

const customTheme = {
  palette: {
    primary: {
      main: "#6A2CB1",
    },
    secondary: {
      main: "#DE4A82",
    },
    neutral: {
      main: "#F7F3FA",
    },
    warning: {
      main: "#FFC63F",
    },
    success: {
      main: "#63D197",
    },
    error: {
      main: "#FF8B63",
    },
    additionalColors: {
      radioButtonBackground: "#FFFFFF00",
      commonBackground: "#e5e5e5",
      imageContainerBackground: "#919191",
      cancel: "#ff2f00",
      timeRulesScheduler: {
        open: { color: "#108150", background: "rgba(229, 255, 245, 0.5)" },
        closed: { color: "#A02B19", background: "rgba(255, 234, 230, 0.5)" },
      },
      timeRulesEditionModal: {
        commomBackground: "#EEEEEE",
      },
    },
  },
  typography: {
    fontFamily: "Open Sans, sans-serif",
    fontWeightMediumBold: 600,
    h1: {
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: "4.5rem",
      letterSpacing: "-0.007em",
    },
    h2: {
      fontWeight: 400,
      fontSize: "2.5rem",
      lineHeight: "4rem",
      letterSpacing: "-0.0025em",
    },
    h3: {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "3rem",
    },
    h4: {
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "2rem",
      letterSpacing: "-0.05em",
    },
    h6: {
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "1.25rem",
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.625rem",
      lineHeight: "1rem",
      textTransform: "none",
    },
  },
};

export const useCreateCustomTheme = (): ThemeOptions => {
  const theme = useTheme();

  return {
    palette: {
      primary: mountPaletteOption(
        customTheme.palette?.primary as SimplePaletteColorOptions
      ),
      secondary: mountPaletteOption(
        customTheme.palette?.secondary as SimplePaletteColorOptions
      ),
      additionalColors: {
        ...customTheme.palette?.additionalColors,
      },
      neutral: {
        superDarkest: "#C8C1D0",
        darkest: "#DCD6E2",
        dark: "#ECE6F0",
        main: "#F7F3FA",
        light: "#FBF9FC",
        lightest: "#FFFFFF",
        superLightest: "#FFFFFF",
      },
      warning: mountPaletteOption(
        customTheme.palette?.warning as SimplePaletteColorOptions
      ),
      success: mountPaletteOption(
        customTheme.palette?.success as SimplePaletteColorOptions
      ),
      error: mountPaletteOption(
        customTheme.palette?.error as SimplePaletteColorOptions
      ),
    },
    typography: {
      ...(customTheme.typography as any),
      allVariants: {
        color: "#61596C",
      },
      color: {
        superDarkest: "#261C3E",
        darkest: "#372C4D",
        dark: "#4B415C",
        main: "#61596C",
        light: "#8E8797",
      },
    },
    breakpoints: {
      values: {
        ...theme.breakpoints.values,
        md: 1024,
        sm: 768,
      },
    },
  };
};

const mountPaletteOption = (
  palette: SimplePaletteColorOptions
): CustomSimplePaletteColorOptions => ({
  ...palette,
  superDarkest: Color(palette.main, "hex").darken(0.5).hex(),
  darkest: Color(palette.main, "hex").darken(0.45).hex(),
  dark: Color(palette.main, "hex").darken(0.3).hex(),
  light: Color(palette.main, "hex").lighten(0.3).hex(),
  lightest: Color(palette.main, "hex").lighten(0.45).hex(),
  superLightest: Color(palette.main, "hex").lighten(0.5).hex(),
});
