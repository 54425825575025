import { Box } from "@material-ui/core";
import { Progress } from "pp-admin-commons";
import { useStyles } from "./progress-page.styles";

export default function ProgressPage() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Progress size={60} />
    </Box>
  );
}
