import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import Button from "../../../../common/componentes/button/button";
import ModalContainer from "../../../../common/componentes/modal-container/modal-container";
import { CancelationCodes } from "../../types/orders";
import CancelationCodeLabel from "../cancelation-code-label/cancelation-code-label";
import { useStyles } from "./cancelation-modal.styles";

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onCancel(code: CancelationCodes, reason?: string): void;
}

export default function CancelationModal({ open, onClose, onCancel }: Props) {
  const classes = useStyles();
  const [code, setCode] = useState<CancelationCodes>(null);
  const [reason, setReason] = useState<string | undefined>(undefined);

  const handleCodeSelection = useCallback(
    (code: CancelationCodes) => setCode(code),
    []
  );

  const handleReasonChange = useCallback(
    (newReason: string) => setReason(newReason),
    []
  );

  return (
    <ModalContainer open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Typography className={classes.title}>
          Qual é o motivo do cancelamento?
        </Typography>
        <Typography className={classes.subtitle}>
          Escolha as opções que justifique o cancelamento do pedido. A resposta
          não será enviada para o cliente.
        </Typography>
        <Grid container spacing={3} className={classes.codeModal}>
          <Grid item>
            <CancelationCodeLabel
              title="Sem ingrediente"
              isSelected={code === CancelationCodes.UNAVAILABLE_ITEM}
              onClick={() =>
                handleCodeSelection(CancelationCodes.UNAVAILABLE_ITEM)
              }
            />
          </Grid>
          <Grid item>
            <CancelationCodeLabel
              title="Pagamento indisponível"
              isSelected={code === CancelationCodes.SYSTEMIC_ISSUES}
              onClick={() =>
                handleCodeSelection(CancelationCodes.SYSTEMIC_ISSUES)
              }
            />
          </Grid>
          <Grid item>
            <CancelationCodeLabel
              title="Entregador indisponível"
              isSelected={
                code === CancelationCodes.RESTAURANT_WITHOUT_DELIVERY_MAN
              }
              onClick={() =>
                handleCodeSelection(
                  CancelationCodes.RESTAURANT_WITHOUT_DELIVERY_MAN
                )
              }
            />
          </Grid>
          <Grid item>
            <CancelationCodeLabel
              title="Pedido atrasou"
              isSelected={
                code ===
                CancelationCodes.INTERNAL_DIFFICULTIES_OF_THE_RESTAURANT
              }
              onClick={() =>
                handleCodeSelection(
                  CancelationCodes.INTERNAL_DIFFICULTIES_OF_THE_RESTAURANT
                )
              }
            />
          </Grid>
          <Grid item>
            <CancelationCodeLabel
              title="Fora do horário de funcionamento"
              isSelected={code === CancelationCodes.OUTSIDE_DELIVERY_HOURS}
              onClick={() =>
                handleCodeSelection(CancelationCodes.OUTSIDE_DELIVERY_HOURS)
              }
            />
          </Grid>
        </Grid>
        <Box className={classes.reasonContainer}>
          <Typography className={classes.reasonLabel}>Outro motivo</Typography>
          <TextField
            value={reason}
            onChange={(e) => handleReasonChange(e.target.value)}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={!Boolean(code)}
            onClick={() => onCancel(code, reason)}
          >
            Enviar
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
}
