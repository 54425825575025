import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../configs/api";
import { OrderResponse } from "../types/historic-orders";

type IGetOrderResponse = {
  content: OrderResponse[];
  number: number;
  size: string;
  totalElements: string;
  hasContent: boolean;
};

type IGetOrderTotalResponse = {
  totalValue: number;
  totalDiscounts: number;
  totalOrders: number;
  totalValuePtg: any;
  totalDiscountPtg: any;
  totalOrdersPtg: any;
};

export const ordersHistoricApi = createApi({
  reducerPath: "ordersHistoricApi",
  baseQuery: axiosBaseQuery(),
  endpoints(builder) {
    return {
      getHistoricOrders: builder.query<IGetOrderResponse, string>({
        query: (params) => {
          return {
            url: `statistics/history?${params}`,
            method: "GET",
          };
        },
      }),
      getTotalOrders: builder.query<IGetOrderTotalResponse, string>({
        query: (days) => {
          return {
            url: `statistics/total-orders?period=${days}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useGetHistoricOrdersQuery, useGetTotalOrdersQuery } =
  ordersHistoricApi;
