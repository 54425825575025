import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../configs/api";
import { CancelationCodes, OrderResponse } from "../types/orders";

type ICancelOrderRequest = {
  orderId: string;
  reason: string;
  code: CancelationCodes;
};

type IConfirmOrderRequest = {
  orderId: string;
  displayId: string;
};

type IDispatchOrderRequest = {
  orderId: string;
};

type IReadyForPickupOrderRequest = {
  orderId: string;
};

type IFinishOrderRequest = {
  orderId: string;
  type: string;
};

type IGetMerchantResponse = {
  merchantId: string;
  name: string;
  document: string;
  companyId: string;
  status: string;
};

type IGetOrderResponse = {
  PICKUP_AREA_ASSIGNED: OrderResponse[];
  CANCELLED: OrderResponse[];
  CREATED: OrderResponse[];
  ORDER_CANCELLATION_REQUEST: OrderResponse[];
  READY_FOR_PICKUP: OrderResponse[];
  CONFIRMED: OrderResponse[];
  CANCELLATION_DENIED: OrderResponse[];
  CANCELLATION_REQUESTED: OrderResponse[];
  CANCELLATION_REQUEST_DENIED: OrderResponse[];
  DISPATCHED: OrderResponse[];
  CONCLUDED: OrderResponse[];
};

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: axiosBaseQuery(),
  endpoints(builder) {
    return {
      getOrders: builder.query<IGetOrderResponse, void>({
        query: () => {
          return {
            url: "orders",
            method: "GET",
          };
        },
      }),
      confirmOrder: builder.mutation<void, IConfirmOrderRequest>({
        query: ({ orderId, displayId }) => {
          return {
            url: `orders/${orderId}/confirm?externalCode=${displayId}`,
            method: "POST",
          };
        },
      }),
      cancelOrder: builder.mutation<void, ICancelOrderRequest>({
        query: ({ orderId, ...data }) => {
          return {
            url: `orders/${orderId}/requestCancellation`,
            method: "POST",
            data,
          };
        },
      }),
      dispatchOrder: builder.mutation<void, IDispatchOrderRequest>({
        query: ({ orderId }) => {
          return {
            url: `orders/${orderId}/dispatch?orderType=DELIVERY`,
            method: "POST",
          };
        },
      }),
      readyForPickupOrder: builder.mutation<void, IReadyForPickupOrderRequest>({
        query: ({ orderId }) => {
          return {
            url: `orders/${orderId}/conclude?eventType=CONCLUDED`,
            method: "POST",
          };
        },
      }),
      finishOrder: builder.mutation<void, IFinishOrderRequest>({
        query: ({ orderId, type }) => {
          return {
            url: `orders/${orderId}/conclude?eventType=${type}`,
            method: "POST",
          };
        },
      }),
      getMerchant: builder.query<IGetMerchantResponse, void>({
        query: () => {
          return {
            url: "merchant",
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useGetOrdersQuery,
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useDispatchOrderMutation,
  useReadyForPickupOrderMutation,
  useFinishOrderMutation,
  useGetMerchantQuery,
} = ordersApi;
