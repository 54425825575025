import { Box, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Input from "../Input/input";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

import loginValidateSchema from "../../validate-schema/login.js";
import { useStyles } from "./login-form.styles";
import { useAuth } from "../../../../common/hooks/auth/auth";

export default function LoginForm() {
  const classes = useStyles();
  const { signIn } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidateSchema),
  });

  const onSubmit = async (data: {
    email: string;
    password: string;
  }): Promise<void> => {
    try {
      await signIn(data.email, data.password);
      reset();
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Ops",
        text: "Ocorreu um erro! Por favor, confira os dados e tente novamente!",
        footer:
          "<span>Precisa de ajuda? Entre em contato com a nossa equipe de suporte <b>ajuda@pedepronto.com.br</b> ou <b>4020-1739</b>.</span>",
        showConfirmButton: true,
        confirmButtonText: "Fechar",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Input
          errors={errors}
          label="E-mail"
          type="email"
          {...register("email")}
        />
        <Input
          errors={errors}
          label="Senha"
          type="password"
          {...register("password")}
          revealPassword
        />
        <Button variant="contained" className={classes.btnSubmit} type="submit">
          Entrar
        </Button>
      </Box>
    </form>
  );
}
