import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ordersApi } from "../features/orders/slice/orders.api";
import { ordersHistoricApi } from "../features/historic-orders/slice/historic-orders.api";
import { ordersSlice } from "../features/orders/slice/orders.slice";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

const reducers = combineReducers({
  ordersSlice: ordersSlice.reducer,
  [ordersHistoricApi.reducerPath]: ordersHistoricApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [ordersSlice.name],
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(ordersApi.middleware)
      .concat(ordersHistoricApi.middleware);
  },
});

export const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
