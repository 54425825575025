import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./empty-message.styles";

interface emptyMessageProps {
  message: string;
}

export default function EmptyMessage({ message }: emptyMessageProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.message}>{message}</Typography>
    </Box>
  );
}
