import { useStyles } from "./total-cards.styles";
import { Box, Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useCallback } from "react";
import { useGetTotalOrdersQuery } from "../../slice/historic-orders.api";
import iconInfo from "../../../../assets/svgs/info-total-order.svg";
import arrowGreen from "../../../../assets/svgs/arrow-green.svg";
import arrowRed from "../../../../assets/svgs/arrow-red.svg";
import { config } from "../../../../configs/config";

interface TotalCardsProps {
  days: string;
}

export default function TotalCards({ days }: TotalCardsProps) {
  const classes = useStyles();

  const totalOrder = useGetTotalOrdersQuery(days, {
    pollingInterval: config.pollingInterval,
  });

  const formatMoney = useCallback((money: number) => {
    return `R$ ${money.toFixed(2).replace(".", ",")}`;
  }, []);

  const formatPerc = useCallback((money: number) => {
    return `${money.toFixed(1).replace(".", ",")}%`;
  }, []);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#e4c6f8",
      color: "#4b415C",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <Box display="flex" className={classes.container}>
      <Grid container item xs={10} sm={10}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Typography className={`${classes.title} ${classes.titleAlign}`}>
              Total de pedidos
              <LightTooltip
                title="Total de pedidos confirmados de acordo com o filtro selecionado."
                placement="top-end"
                className={classes.iconInfo}
              >
                <IconButton>
                  <img src={iconInfo} alt="Icone Info" />
                </IconButton>
              </LightTooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={`${classes.title} ${classes.titleAlign}`}>
              Total bruto faturado
              <LightTooltip
                title="Total bruto faturado de acordo com o filtro selecionado."
                placement="top-end"
                className={classes.iconInfo}
              >
                <IconButton>
                  <img src={iconInfo} alt="Icone Info" />
                </IconButton>
              </LightTooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={`${classes.title} ${classes.titleAlign}`}>
              Total bruto faturado em incentivos
              <LightTooltip
                title="Total bruto de incentivos de pedidos confirmados na última semana de acordo com o filtro selecionado.
                 Esse valor não representa o valor único de repasse para recebimento."
                placement="top-end"
                className={classes.iconInfo}
              >
                <IconButton className={classes.hoverIconButton}>
                  <img src={iconInfo} alt="Icone Info" />
                </IconButton>
              </LightTooltip>
            </Typography>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.card}>
              <Typography className={classes.textTotal}>
                {totalOrder?.data?.totalOrders}
              </Typography>
              <Typography
                className={
                  totalOrder?.data?.totalOrdersPtg != null &&
                  totalOrder?.data?.totalOrdersPtg >= 0
                    ? classes.positivePerc
                    : classes.negativePerc
                }
              >
                {totalOrder?.data?.totalOrdersPtg != null && (
                  <img
                    src={
                      totalOrder?.data?.totalOrdersPtg != null &&
                      totalOrder?.data?.totalOrdersPtg >= 0
                        ? arrowGreen
                        : arrowRed
                    }
                    alt="Icone Seta"
                    className={classes.spaceIcon}
                  />
                )}
                {totalOrder?.data?.totalOrdersPtg != null
                  ? formatPerc(totalOrder?.data?.totalOrdersPtg)
                  : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.card}>
              <Typography className={classes.textTotal}>
                {totalOrder?.data
                  ? formatMoney(totalOrder?.data?.totalValue)
                  : ""}
              </Typography>
              <Typography
                className={
                  totalOrder?.data?.totalValuePtg != null &&
                  totalOrder?.data?.totalValuePtg >= 0
                    ? classes.positivePerc
                    : classes.negativePerc
                }
              >
                {totalOrder?.data?.totalValuePtg != null && (
                  <img
                    src={
                      totalOrder?.data?.totalValuePtg != null &&
                      totalOrder?.data?.totalValuePtg >= 0
                        ? arrowGreen
                        : arrowRed
                    }
                    alt="Icone Seta"
                    className={classes.spaceIcon}
                  />
                )}
                {totalOrder?.data?.totalValuePtg != null
                  ? formatPerc(totalOrder?.data?.totalValuePtg)
                  : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.card}>
              <Typography className={classes.textTotal}>
                {totalOrder?.data
                  ? formatMoney(totalOrder?.data?.totalDiscounts)
                  : ""}
              </Typography>
              <Typography
                className={
                  totalOrder?.data?.totalDiscountPtg != null &&
                  totalOrder?.data?.totalDiscountPtg >= 0
                    ? classes.positivePerc
                    : classes.negativePerc
                }
              >
                {totalOrder?.data?.totalDiscountPtg != null && (
                  <img
                    src={
                      totalOrder?.data?.totalDiscountPtg != null &&
                      totalOrder?.data?.totalDiscountPtg >= 0
                        ? arrowGreen
                        : arrowRed
                    }
                    alt="Icone Seta"
                    className={classes.spaceIcon}
                  />
                )}
                {totalOrder?.data?.totalDiscountPtg != null
                  ? formatPerc(totalOrder?.data?.totalDiscountPtg)
                  : ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
