import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontWeight: "bold",
    },
    card: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
      border: "1px solid #ECE6F0",
      borderRadius: "4px",
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "space-between",
    },
    textTotal: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      color: "#000000",
    },
    positivePerc: {
      color: "#60D6B1",
    },
    negativePerc: {
      color: "#DD3344",
    },
    iconInfo: {
      marginLeft: theme.spacing(2),
    },
    titleAlign: {
      display: "flex",
      alignItems: "center",
    },
    hoverIconButton: {
      backgroundColor: "transparent",
    },
    spaceIcon: {
      marginRight: theme.spacing(1),
    },
  })
);
