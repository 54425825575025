import React, { ReactElement } from "react";

import {
  createGenerateClassName,
  createTheme,
  MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core";

import { useCreateCustomTheme } from "../../hooks/theme/create-custom-theme";

const generateClassName = createGenerateClassName({
  productionPrefix: "orders-app",
  seed: "pp-orders",
});

export default function ThemeHandler(
  props: React.PropsWithChildren<any>
): ReactElement {
  const customTheme = useCreateCustomTheme();

  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={createTheme(customTheme)}>
        {props.children}
      </MuiThemeProvider>
    </StylesProvider>
  );
}
