export default (subType: any): string => {
  switch (subType) {
    case "EVENT":
      return "Retirar no local";
    case "COUNTER":
      return "Retirar na bandeja";
    case "TO_GO":
      return "Retirar embalado";
    default:
      return "Retirada";
  }
};
