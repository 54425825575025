import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "34rem",
    },
    title: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    subtitle: {
      marginTop: theme.spacing(1),
    },
    codeModal: {
      marginTop: theme.spacing(1),
    },
    reasonContainer: {
      marginTop: theme.spacing(1),
    },
    reasonLabel: {
      color: "#61596C",
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);
