/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { isTokenExpired } from "pp-admin-commons";

const api = axios.create({
  baseURL: "https://api.pedepronto.com.br/v1/order-manager",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

let isRefreshingToken = false;
let failRequestQueue = [];

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error: AxiosError) {
    const originalConfig = error.config;
    const token = localStorage.getItem("orders-token");

    if (error.response.status === 401 || isTokenExpired(token)) {
      if (!isRefreshingToken) {
        isRefreshingToken = true;
        const refresh_token = localStorage.getItem("orders-refresh-token");

        axios({
          method: "post",
          url: `https://securetoken.googleapis.com/v1/token?key=AIzaSyCPmHJwSgcIMwlgNVo9W7ZA1MfsuIKUVwk&grant_type=refresh_token&refresh_token=${refresh_token}`,
        })
          .then((response) => {
            const { access_token, refresh_token } = response.data;

            api.defaults.headers[
              "HTTP_AUTHORIZATION"
            ] = `Bearer ${access_token}`;

            localStorage.setItem("orders-token", access_token);
            localStorage.setItem("orders-refresh-token", refresh_token);

            failRequestQueue.forEach((request) =>
              request.onSuccess(access_token)
            );

            failRequestQueue = [];
          })
          .catch((err) => {
            failRequestQueue.forEach((request) => request.onFailure(err));
            failRequestQueue = [];
          })
          .finally(() => {
            isRefreshingToken = false;
          });
      }

      return new Promise((resolve, reject) => {
        failRequestQueue.push({
          onSuccess: (token: string) => {
            originalConfig.headers["HTTP_AUTHORIZATION"] = `Bearer ${token}`;

            resolve(api(originalConfig));
          },
          onFailure: (err: AxiosError) => {
            reject(err);
          },
        });
      });
    }

    return Promise.reject(error);
  }
);

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await api.request({
        url,
        method,
        data,
        params,
        headers: {
          ...api.defaults.headers.common,
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default api;
