import { Button } from "@material-ui/core";
import { useStyles } from "./button-order.styles";
import { OrderItem } from "../../slice/orders.slice";
import { IEventType } from "../../types/orders";

interface OrderCardProps {
  eventType: IEventType;
  onClick(): void;
  orderId: string;
}

export default function ButtonOrder({
  eventType,
  onClick,
  orderId,
}: OrderCardProps) {
  const classes = useStyles();

  const textButton = () => {
    switch (eventType) {
      case "CONFIRMED":
        return "Pedido Pronto";
      case "DISPATCHED":
      case "READY_FOR_PICKUP":
      case "PICKUP_AREA_ASSIGNED":
        return "Finalizar";

      default:
        return "Aceitar pedido";
    }
  };

  return (
    <Button
      variant="contained"
      id={`order-card-button-confirm-${orderId}`}
      className={classes.button}
      onClick={onClick}
    >
      {textButton()}
    </Button>
  );
}
