import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(100vh - 100px)",
      width: "100%",
      borderLeft: "1px solid #eee",
    },
    content: {
      width: "100%",
      maxWidth: "730px",
      background: "#fff",
      boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.25)",
      borderRadius: 8,
      margin: 20,
      padding: "40px 160px 40px 160px",
      [theme.breakpoints.down("xs")]: {
        padding: "40px 60px",
      },
    },
    title: {
      fontSize: "1.5rem !important",
      fontWeight: "700 !important" as any,
      color: "#6A2CB1 !important",
      marginTop: "32px !important",
      marginBottom: "62px !important",
      textAlign: "center",
    },
    logo: {
      display: "block",
      margin: "0 auto",
    },
  })
);
