import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      background: theme.palette.neutral?.light,
      flexDirection: "column",
    },
    contentContainer: {
      display: "flex",
    },
  })
);
