import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../services/auth";

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    logout().then(() => {
      navigate("/menu");
    });
  }, [navigate]);

  return <div>carregando...</div>;
}
