import { useStyles } from "./table-historic.styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useCallback, useState } from "react";

import { useGetHistoricOrdersQuery } from "../../slice/historic-orders.api";
import { config } from "../../../../configs/config";
import getSubTakewayDescription from "../../../../common/helpers/get-sub-takeway-description";

interface TableHistoricProps {
  days: string;
}

export default function TableHistoric({ days }: TableHistoricProps) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [pageLabel, setPageLabel] = useState(1);

  const handleChange = (event, value) => {
    const p = value - 1;
    setPage(p);
    setPageLabel(value);
  };

  const orders = useGetHistoricOrdersQuery(
    `period=${days}&size=10&page=${page}&sort=createdAt,desc`,
    {
      pollingInterval: config.pollingInterval,
    }
  );
  const pages = Math.ceil(
    parseInt(orders.currentData?.totalElements) /
      parseInt(orders.currentData?.size)
  );

  const formatCurrency = useCallback((currency: string, amount: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: currency,
    }).format(amount);
  }, []);

  const formatDateTime = useCallback((date: string) => {
    const parseDate = new Date(date);
    const month = parseDate.toLocaleDateString("pt-BR");
    const hours = parseDate.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${month} às ${hours}`;
  }, []);

  return (
    <Box display="flex" className={classes.container}>
      <Grid container item xs={12} sm={12}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography className={classes.title}>
              Histórico de Pedidos
            </Typography>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={12} sm={12}>
            <Box className={classes.historicTable}>
              <Grid container item>
                <Grid item xs={2} className={classes.marginBottomTable}>
                  <Typography
                    className={`${classes.label} ${classes.marginLeft}`}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.marginBottomTable}>
                  <Typography className={classes.label}>Pedido</Typography>
                </Grid>
                <Grid item xs={2} className={classes.marginBottomTable}>
                  <Typography className={classes.label}>Data e Hora</Typography>
                </Grid>
                <Grid item xs={2} className={classes.marginBottomTable}>
                  <Typography className={classes.label}>
                    Forma de Entrega
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.marginBottomTable}>
                  <Typography className={classes.label}>Valor Bruto</Typography>
                </Grid>
                <Grid item xs={1} className={classes.marginBottomTable}>
                  <Typography className={classes.label}>Desconto</Typography>
                </Grid>
                <Grid item xs={2} className={classes.marginBottomTable}>
                  <Typography className={classes.label}>Valor Total</Typography>
                </Grid>
              </Grid>
              {orders.currentData?.content?.map((order) => (
                <Grid container item key={order.id}>
                  <Grid item xs={2} className={classes.marginBottomTable}>
                    <Typography
                      className={`${classes.statusValue} ${classes.marginLeft}`}
                    >
                      {order.eventType == "CONCLUDED" && (
                        <Box
                          bgcolor="#E6F9F3"
                          color="#15803D"
                          padding="4px 12px"
                          component="span"
                          borderRadius="4px"
                        >
                          Concluído
                        </Box>
                      )}
                      {order.eventType === "CANCELLED" && (
                        <Box
                          bgcolor="#FDE0D6"
                          color="#DD3344"
                          padding="4px 12px"
                          component="span"
                          borderRadius="4px"
                        >
                          Cancelado
                        </Box>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.marginBottomTable}>
                    <Typography className={classes.value}>
                      {order.displayId}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.marginBottomTable}>
                    <Typography className={classes.value}>
                      {formatDateTime(order.createdAt).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.marginBottomTable}>
                    <Typography className={classes.value}>
                      {order.type == "DELIVERY"
                        ? "Delivery"
                        : getSubTakewayDescription(order.subType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.marginBottomTable}>
                    <Typography className={classes.value}>
                      {formatCurrency(
                        order.total.itemsPrice.currency,
                        order.total.itemsPrice.value +
                          order.total.otherFees.value
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.marginBottomTable}>
                    <Typography className={classes.value}>
                      {order.total.discount.value > 0 &&
                        "- " +
                          formatCurrency(
                            order.total.discount.currency,
                            order.total.discount.value
                          )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.marginBottomTable}>
                    <Typography className={classes.value}>
                      {formatCurrency(
                        order.total.orderAmount.currency,
                        order.total.orderAmount.value
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
        {pages > 1 && (
          <Grid container item spacing={3} className={classes.container}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.paginationPosition}
            >
              <Pagination
                count={pages}
                page={pageLabel}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                hidePrevButton
                hideNextButton
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
