import { Box } from "@material-ui/core";
import { useStyles } from "./logistic-steps.styles";

interface LogisticStepsProps {
  step?: number;
}

export default function LogisticSteps({ step }: LogisticStepsProps) {
  const classes = useStyles({ step: step });

  return (
    <Box className={classes.container}>
      {[1, 2, 3, 4, 5].map((item) => (
        <Box
          key={item}
          className={`
          ${classes.step} 
          ${step > item ? "active" : ""}
          ${step == item ? "actual" : ""}`}
        />
      ))}
    </Box>
  );
}
