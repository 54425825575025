import React from "react";

import { Typography } from "@material-ui/core";

import { useStyles } from "./page-title.styles";

interface Props {
  title: string;
  className?: string;
}

export default function PageTitle({ title, className }: Props) {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={`${classes.title} ${className}`}>
      {title}
    </Typography>
  );
}
