import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(19),
      marginBottom: theme.spacing(19),
      padding: theme.spacing(1),
    },
    message: {
      color: "#B7B4BB",
      textAlign: "center",
    },
  })
);
