import { Badge, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./grid-header.styles";

interface GridHeaderProps {
  title: string;
  borderColor: string;
  count: number;
}

export default function GridHeader({
  title,
  borderColor,
  count,
}: GridHeaderProps) {
  const classes = useStyles({ borderColor });

  return (
    <Paper className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Badge badgeContent={count.toString()} className={classes.badge} />
    </Paper>
  );
}
