import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { auth } from "../../../configs/firebase";
import api from "../../../configs/api";

type AuthContextData = {
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  token: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useState("");

  async function signIn(email: string, password: string) {
    signInWithEmailAndPassword(auth, email, password)
      .then((account) => {
        account.user.getIdTokenResult().then(({ token }) => {
          localStorage.setItem("orders-token", token);
          localStorage.setItem(
            "orders-refresh-token",
            account.user.refreshToken
          );
          api.defaults.headers["HTTP_AUTHORIZATION"] = `Bearer ${token}`;
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async function signOut() {
    await firebaseSignOut(auth);
    localStorage.removeItem("orders-token");
    localStorage.removeItem("orders-refresh-token");
  }

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("orders-token");

      api.defaults.headers["HTTP_AUTHORIZATION"] = `Bearer ${token}`;
      setToken(token);
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
