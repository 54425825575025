import { makeStyles, Theme, createStyles } from "@material-ui/core";

interface Props {
  borderColor: string;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      padding: 20,
      position: "relative",
      "&::after": {
        content: "''",
        display: "block",
        width: "100%",
        height: 4,
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: (props) => props.borderColor,
        borderRadius: 8,
      },
    },
    title: {
      fontSize: "1.125rem",
      fontWeight: 600,
    },
    badge: {
      "& span": {
        transform: "initial",
        backgroundColor: theme.palette.additionalColors.commonBackground,
      },
    },
  })
);
