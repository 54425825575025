import React, { ReactElement } from "react";
import { useStyles } from "./modal-container.styles";
import { Box, Fade, IconButton, Modal } from "@material-ui/core";
import CloseIcon from "../../../assets/svgs/close.svg";

interface Props {
  open: boolean;
  onClose?: VoidFunction;
  children: ReactElement;
  showCloseButton?: boolean;
}

export default function ModalContainer({
  open,
  onClose,
  children,
  showCloseButton = true,
}: Props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.container}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className={classes.contentContainer}>
          {showCloseButton && (
            <IconButton onClick={onClose} className={classes.icon}>
              <img src={CloseIcon} alt="Ícone de fechar" />
            </IconButton>
          )}
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
