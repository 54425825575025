import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    header: {
      borderBottom: "1px solid #E2E1E3",
      paddingBottom: theme.spacing(1),
    },
    body: {
      paddingTop: theme.spacing(1),
    },
    footer: {
      paddingTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    badge: {
      width: 90,
      height: 24,
      borderRadius: 24,
      transform: "initial",
      backgroundColor: "#D8FBDE",
      fontSize: "0.75rem",
      padding: theme.spacing(0, 1),
      fontWeight: 600,
      color: "#2D2931",
      position: "absolute",
      right: 0,
      top: 0,
    },
    badgeInterval: {
      width: 90,
      height: 24,
      borderRadius: 24,
      transform: "initial",
      backgroundColor: "#FFF5CC",
      fontSize: "0.75rem",
      padding: theme.spacing(0, 1),
      fontWeight: 600,
      color: "#2D2931",
      position: "absolute",
      right: 0,
      top: 0,
    },
    btnInfo: {
      color: "#4B415C",
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "inherit",
    },
    btnRecall: {
      padding: theme.spacing(0, 1),
      textTransform: "none",
      height: "1.5rem",
      "&:hover": {
        background: "transparent",
      },
    },
    textRecall: {
      fontSize: "0.875rem",
      color: "#DE4A82",
      borderBottom: "1px solid #DE4A82",
      fontWeight: 600,
    },
    textInfo: {
      fontSize: "0.875rem",
    },
    textOrigin: {
      color: "#35007D",
    },
    campaignIcon: {
      maxWidth: "0.75rem",
      margin: theme.spacing(0.625, 1.25, 1.25, 1.25),
    },
    logistic: {
      display: "flex",
      flexDirection: "column",
    },
    logistic_description: {
      display: "flex",
      fontWeight: "bold",
      fontSize: "0.75rem",
    },
  })
);
