import axios from "axios";
import { createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { IEventType, IItem, OrderResponse } from "../types/orders";
import { ordersApi } from "./orders.api";
import uniqid from "uniqid";
import { differenceInMilliseconds } from "date-fns";
import { config } from "../../../configs/config";
import { flatten } from "lodash";
import { normalizeOrderItem } from "../../../common/helpers/normalize-order-item";
import LayoutPrint from "../../../common/helpers/layout-print";
import isToday from "date-fns/isToday";

export enum Status {
  IDLE = "IDLE",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
}

export type OrderItem = OrderResponse & {
  stopWatch: number | null;
  interval: NodeJS.Timer | null;
  alreadyNotified: boolean;
  stopWatchRunning?: boolean;
  alreadyCanceled: boolean;
  flattenItems: IParsedItem[];
  eventType: IEventType;
};

type IParsedItem = Omit<IItem, "options">;

type Notification = {
  id: string;
  type: "success" | "notification" | "warning" | "error";
  title: string;
  description: string;
};

const initialState = {
  waitingOrders: [] as OrderItem[],
  currentOrder: {} as OrderItem,
  preparingOrders: [] as OrderItem[],
  deliveredOrders: [] as OrderItem[],
  notificationList: [] as Notification[],
  status: Status.IDLE,
};

const { clockFirstLimitInMilli, clockLastLimitInSeconds } = config;

export const startTimers = (
  item: OrderItem
): ThunkAction<any, any, any, any> => {
  return (dispatch, _) => {
    const differenceToNow = differenceInMilliseconds(
      new Date(),
      new Date(item.createdAt)
    );

    if (item.interval) {
      clearInterval(item.interval);
    }

    if (!item.alreadyNotified) {
      const sound = new Audio(
        "https://storage.googleapis.com/pp-common-data/admin/neworder.mp3"
      );

      sound.play();
      dispatch(alreadyNotified({ id: item.id, eventType: item.eventType }));
    }

    if (differenceToNow <= clockFirstLimitInMilli) {
      setTimeout(() => {
        const interval = setInterval(() => {
          dispatch(updateTimer({ ...item, alreadyNotified: true }));
        }, 1000);

        dispatch(
          notify({
            type: "warning",
            title: "Atenção!",
            description: `Faltam 4 minutos para você aceitar o pedido #${item.displayId}`,
          })
        );

        dispatch(
          initTimer({
            ...item,
            stopWatch: clockLastLimitInSeconds,
            interval,
            stopWatchRunning: true,
            alreadyNotified: true,
          })
        );
      }, clockFirstLimitInMilli - differenceToNow);
    } else {
      const stopWatch =
        clockLastLimitInSeconds -
        (differenceToNow - clockFirstLimitInMilli) / 1000;

      dispatch(
        notify({
          type: "warning",
          title: "Atenção!",
          description: `Faltam aproximadamente ${Math.ceil(
            stopWatch / 60
          )} minutos para você aceitar o pedido #${item.displayId}`,
        })
      );

      const interval = setInterval(() => {
        dispatch(updateTimer({ ...item }));
      }, 1000);

      dispatch(
        initTimer({
          ...item,
          alreadyNotified: true,
          stopWatch,
          stopWatchRunning: true,
          interval,
        })
      );
    }
  };
};

export const confirmedNotifySound = (order: OrderItem) => {
  return (dispatch, _) => {
    if (!order.alreadyNotified) {
      const sound = new Audio(
        "https://storage.googleapis.com/pp-common-data/admin/neworder.mp3"
      );
      sound.play();
      dispatch(alreadyNotified({ id: order.id, eventType: order.eventType }));
    }
  };
};

export const notify = (
  data: Omit<Notification, "id">
): ThunkAction<any, any, any, any> => {
  return (dispatch, _) => {
    const notification = { ...data, id: uniqid.time() };

    dispatch(addNotification({ ...notification }));

    setTimeout(() => {
      dispatch(removeNotification({ id: notification.id }));
    }, 3000);
  };
};

export const ordersSlice = createSlice({
  name: "ordersSlice",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notificationList.push(action.payload);
    },
    setCurrentOrder: (state, action: PayloadAction<OrderItem>) => {
      state.currentOrder = action.payload;
    },
    alreadyNotified: (
      state,
      action: PayloadAction<{ id: string; eventType: string }>
    ) => {
      if (action.payload.eventType === "CREATED") {
        const index = state.waitingOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        state.waitingOrders[index].alreadyNotified = true;
      }

      if (action.payload.eventType === "CONFIRMED") {
        const index = state.preparingOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        state.preparingOrders[index].alreadyNotified = true;
      }
    },
    alreadyCanceled: (state, action: PayloadAction<{ id: string }>) => {
      const index = state.waitingOrders.findIndex(
        (item) => item.id === action.payload.id
      );

      state.waitingOrders[index].alreadyCanceled = true;
      state.waitingOrders[index].stopWatchRunning = false;
    },
    removeNotification: (state, action: PayloadAction<{ id: string }>) => {
      const index = state.notificationList.findIndex(
        (item) => item.id === action.payload.id
      );

      state.notificationList.splice(index, 1);
    },
    initTimer: (state, action: PayloadAction<OrderItem>) => {
      if (action.payload.eventType === "CREATED") {
        const index = state.waitingOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        state.waitingOrders[index] = {
          ...action.payload,
        };
      }

      if (action.payload.eventType === "CONFIRMED") {
        const index = state.preparingOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        state.preparingOrders[index] = {
          ...action.payload,
        };
      }

      if (
        action.payload.eventType === "DISPATCHED" ||
        action.payload.eventType === "READY_FOR_PICKUP" ||
        action.payload.eventType === "PICKUP_AREA_ASSIGNED"
      ) {
        const index = state.deliveredOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        state.deliveredOrders[index] = {
          ...action.payload,
        };
      }
    },
    updateTimer: (state, action: PayloadAction<OrderItem>) => {
      if (
        action.payload.eventType === "CREATED" &&
        state.waitingOrders.length
      ) {
        const index = state.waitingOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        const { stopWatch } = state.waitingOrders[index];

        if (stopWatch && stopWatch >= 1) {
          state.waitingOrders[index].stopWatch = stopWatch - 1;
        } else {
          state.waitingOrders[index].stopWatch = null;
        }
      }

      if (
        action.payload.eventType === "CONFIRMED" &&
        state.preparingOrders.length
      ) {
        const index = state.preparingOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        const { stopWatch } = state.preparingOrders[index];

        if (stopWatch && stopWatch >= 1) {
          state.preparingOrders[index].stopWatch = stopWatch - 1;
        } else {
          state.preparingOrders[index].stopWatch = null;
        }
      }

      if (
        action.payload.eventType === "DISPATCHED" ||
        action.payload.eventType === "READY_FOR_PICKUP" ||
        (action.payload.eventType === "PICKUP_AREA_ASSIGNED" &&
          state.deliveredOrders.length)
      ) {
        const index = state.deliveredOrders.findIndex(
          (item) => item.id === action.payload.id
        );
        const { stopWatch } = state.deliveredOrders[index];

        if (stopWatch && stopWatch >= 1) {
          state.deliveredOrders[index].stopWatch = stopWatch - 1;
        } else {
          state.deliveredOrders[index].stopWatch = null;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(ordersApi.endpoints.getOrders.matchPending, (state) => {
        state.status = Status.PENDING;
      })
      .addMatcher(
        ordersApi.endpoints.getOrders.matchFulfilled,
        (state, { payload }) => {
          state.status = Status.SUCCEEDED;

          state.waitingOrders = payload.CREATED.map((order) => {
            const parsedDate = `${order.createdAt}Z`;
            const eventType = "CREATED";

            const flattenItems = order.items.map((item) => {
              let orderItem = Object.assign({}, item);
              delete orderItem.options;

              if (item.options) {
                return [{ ...orderItem }, ...item.options];
              }

              return [orderItem];
            });

            const orderInCache =
              state.waitingOrders &&
              state.waitingOrders.find((item) => item.id === order.id);

            if (orderInCache) {
              const stopWatch = orderInCache.stopWatch;
              const interval = orderInCache.interval;
              const alreadyNotified = orderInCache.alreadyNotified;
              const alreadyCanceled = orderInCache.alreadyCanceled;
              const stopWatchRunning = orderInCache.stopWatchRunning;

              return {
                ...order,
                interval,
                stopWatch,
                createdAt: parsedDate,
                alreadyNotified,
                alreadyCanceled,
                stopWatchRunning,
                eventType,
                flattenItems: flatten(flattenItems),
              };
            }

            return {
              ...order,
              interval: null,
              stopWatch: null,
              createdAt: parsedDate,
              alreadyNotified: false,
              alreadyCanceled: false,
              eventType,
              flattenItems: flatten(flattenItems),
            };
          });

          state.preparingOrders = payload.CONFIRMED.map((order) => {
            const parsedDate = `${order.createdAt}Z`;
            const eventType = "CONFIRMED";

            const flattenItems = order.items.map((item) => {
              let orderItem = Object.assign({}, item);
              delete orderItem.options;

              if (item.options) {
                return [{ ...orderItem }, ...item.options];
              }

              return [orderItem];
            });

            const orderInCache =
              state.preparingOrders &&
              state.preparingOrders.find((item) => item.id === order.id);

            if (orderInCache) {
              const stopWatch = orderInCache.stopWatch;
              const interval = orderInCache.interval;
              const alreadyNotified = orderInCache.alreadyNotified;
              const alreadyCanceled = orderInCache.alreadyCanceled;
              const stopWatchRunning = orderInCache.stopWatchRunning;

              return {
                ...order,
                interval,
                stopWatch,
                createdAt: parsedDate,
                alreadyNotified,
                alreadyCanceled,
                stopWatchRunning,
                eventType,
                flattenItems: flatten(flattenItems),
              };
            }

            return {
              ...order,
              interval: null,
              stopWatch: null,
              createdAt: parsedDate,
              alreadyNotified: false,
              alreadyCanceled: false,
              eventType,
              flattenItems: flatten(flattenItems),
            };
          });

          const readyForPickup = payload.READY_FOR_PICKUP.map((order) =>
            normalizeOrderItem(order, "READY_FOR_PICKUP")
          );
          const pickupAreaAssigned = payload.PICKUP_AREA_ASSIGNED.map((order) =>
            normalizeOrderItem(order, "PICKUP_AREA_ASSIGNED")
          );
          const dispatched = payload.DISPATCHED.map((order) =>
            normalizeOrderItem(order, "DISPATCHED")
          );

          const automaticPrinsts = JSON.parse(
            localStorage.getItem("@pp/automatic_prints")
          );

          if (!automaticPrinsts) {
            localStorage.setItem(
              "@pp/automatic_prints",
              JSON.stringify(state.preparingOrders.map((item) => item.id))
            );
          } else {
            const forPrint = state.preparingOrders.filter((item) => {
              return (
                !automaticPrinsts.includes(item.id) &&
                isToday(new Date(item.createdAt))
              );
            });

            const forPrintLimited = forPrint.slice(0, 10);

            const constrolMap = forPrintLimited.map((itemForPrint) => {
              return {
                promises: axios({
                  method: "POST",
                  url: "http://localhost:3030/print",
                  data: LayoutPrint(itemForPrint),
                }),
                id: itemForPrint.id,
              };
            });

            Promise.all(constrolMap.map((item) => item.promises)).then(
              async () => {
                const ids = constrolMap.map((item) => item.id);
                automaticPrinsts.push(...ids);

                localStorage.setItem(
                  "@pp/automatic_prints",
                  JSON.stringify(automaticPrinsts)
                );
              }
            );
          }

          state.deliveredOrders = [
            ...dispatched,
            ...pickupAreaAssigned,
            ...readyForPickup,
          ].sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
        }
      )
      .addMatcher(ordersApi.endpoints.getOrders.matchRejected, (state) => {
        state.status = Status.FAILED;
      });
  },
});

export const {
  addNotification,
  removeNotification,
  updateTimer,
  initTimer,
  alreadyNotified,
  alreadyCanceled,
  setCurrentOrder,
} = ordersSlice.actions;

export const waitingOrdersSelector = (state: RootState) =>
  state.ordersSlice.waitingOrders;
export const preparingOrdersSelector = (state: RootState) =>
  state.ordersSlice.preparingOrders;
export const deliveredOrdersSelector = (state: RootState) =>
  state.ordersSlice.deliveredOrders;
export const notificationListSelector = (state: RootState) =>
  state.ordersSlice.notificationList;
export const currentOrderSelector = (state: RootState) =>
  state.ordersSlice.currentOrder;
