import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, Navigate } from "react-router-dom";
import ProgressPage from "../common/componentes/progress-page/progress-page";
import { auth } from "../configs/firebase";

function PrivateRoute({ children }) {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  const isAuthenticated = !!user && !loading;

  if (loading) {
    return <ProgressPage />;
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/orders/login" state={{ from: location }} />;
}

export default PrivateRoute;
