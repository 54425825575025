import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";

export type SidebarItems = {
  name: string;
  path?: string;
  icon: React.ReactElement;
  subMenu?: {
    name: string;
    path: string;
  }[];
};

const sidebar: SidebarItems[] = [
  {
    name: "Marcas",
    icon: <LabelOutlinedIcon />,
    path: "/brands",
  },
  {
    name: "Lojas",
    icon: <StorefrontOutlinedIcon />,
    path: "/companies",
  },
  {
    name: "Cardápio",
    icon: <DescriptionOutlinedIcon />,
    path: "/menu",
  },
  {
    name: "Horários",
    icon: <WatchLaterOutlinedIcon />,
    path: "/timerules",
  },
];

export default sidebar;
