import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    name: {
      fontWeight: 600,
    },
    separator: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      fontSize: "1.375rem",
    },
    status: {
      marginLeft: theme.spacing(1),
    },
  })
);
