import { Box, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./table-details.styles";
import AlertMessage from "../../../../common/componentes/alert-message/alert-message";
import ProgressPage from "../../../../common/componentes/progress-page/progress-page";
import { useCallback } from "react";
import { OrderItem } from "../../slice/orders.slice";
import getSubTakewayDescription from "../../../../common/helpers/get-sub-takeway-description";

interface TableDetailsProps {
  order: OrderItem;
}

export default function TableDetails({ order }: TableDetailsProps) {
  const classes = useStyles();

  const eventType = order.eventType;

  const formatCurrency = useCallback(
    (currency: string, amount: number) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: currency,
      }).format(amount);
    },
    [order]
  );

  const formatCPF = useCallback(
    (cpf: string) => {
      cpf = cpf.replace(/[^\d]/g, "");

      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    [order]
  );

  const formatDateTime = useCallback(
    (date: string) => {
      const parseDate = new Date(date);
      const month = parseDate.toLocaleDateString("pt-BR");
      const hours = parseDate.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `${month} ás ${hours}`;
    },
    [order]
  );

  if (!order) {
    return <ProgressPage />;
  }

  const getAddress = (deliveryAddress) => {
    if (!deliveryAddress) {
      return "-";
    }

    return `${deliveryAddress.street},  Nº ${deliveryAddress.number}, ${deliveryAddress.city}/${deliveryAddress.state} - ${deliveryAddress.postalCode}`;
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        container
        item
        xs={12}
        className={`${classes.borderBottom} ${classes.content}`}
      >
        <Grid item md={2} sm={4} xs={6}>
          <Typography className={classes.label}>Código do pedido</Typography>
          <Typography className={classes.value}>#{order.displayId}</Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <Typography className={classes.label}>Nome do cliente</Typography>
          <Typography className={classes.value}>
            {order.customer.name}
          </Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <Typography className={classes.label}>CPF</Typography>
          <Typography className={classes.value}>
            {formatCPF(order.customer.documentNumber)}
          </Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <Typography className={classes.label}>Meio de entrega</Typography>
          <Typography className={classes.value}>
            {order.type === "DELIVERY"
              ? "Delivery"
              : getSubTakewayDescription(order.subType)}
          </Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <Typography className={classes.label}>Meio de pagamento</Typography>
          <Typography className={classes.value}>
            {order.payments?.methods[0].type === "PREPAID"
              ? "Online"
              : "Offline"}
          </Typography>
        </Grid>
        <Grid item md={2} sm={4} xs={6}>
          <Typography className={classes.label}>Data e horário</Typography>
          <Typography className={classes.value}>
            {formatDateTime(order.createdAt).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={8}
        className={`${classes.borderBottom} ${classes.borderRight} ${classes.content}`}
      >
        <Typography className={classes.label}>Endereço de entrega</Typography>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item md={12}>
            {order.delivery && eventType !== "CREATED" && (
              <Typography className={classes.value}>
                {getAddress(order?.delivery?.deliveryAddress)}
                <br />
                {order?.delivery?.deliveryAddress?.complement
                  ? order?.delivery?.deliveryAddress?.complement
                  : "( Sem complemento )"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        direction="column"
        className={`${classes.borderBottom} ${classes.content} ${classes.contentPaddingLeft}`}
      ></Grid>
      <Grid
        container
        item
        xs={12}
        md={8}
        direction="column"
        className={`${classes.borderBottom} ${classes.borderRight} ${classes.content}`}
      >
        <Grid container item>
          <Grid
            container
            item
            className={`${classes.borderBottom} ${classes.content}`}
          >
            <Grid container item>
              <Grid item xs={6}>
                <Typography className={classes.label}>
                  Itens do pedido
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Quantidade</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Preço</Typography>
              </Grid>
            </Grid>

            {order.items.map((item) => (
              <Grid container item key={item.id}>
                <Grid item xs={6}>
                  <Typography className={classes.value}>{item.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.value}>
                    {item.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.value}>
                    {formatCurrency(
                      item.totalPrice.currency,
                      item.totalPrice.value
                    )}
                  </Typography>
                </Grid>
                {item.options && (
                  <Grid
                    container
                    item
                    className={`${classes.marginTop2} ${classes.marginBottom}`}
                  >
                    <Grid item xs={12}>
                      <Typography
                        className={`${classes.label} ${classes.marginLeft}`}
                      >
                        Ingredientes adicionais
                      </Typography>
                    </Grid>
                    {item.options &&
                      item.options.map((option, index) => (
                        <Grid container item key={option.id}>
                          <Grid item xs={6}>
                            <Typography
                              className={`${classes.value} ${classes.marginLeft}`}
                            >
                              {option.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography className={classes.value}>
                              {option.quantity}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography className={classes.value}>
                              {formatCurrency(
                                option.totalPrice.currency,
                                option.totalPrice.value
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <Box mt={2} mr={2}>
              <Typography className={classes.label}>Observações</Typography>
              <Typography className={classes.value}>
                {order?.extraInfo}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        direction="column"
        className={`${classes.borderBottom} ${classes.content} ${classes.contentPaddingLeft}`}
      >
        <Typography className={classes.label}>
          Informações de pagamento
        </Typography>
        <Box>
          {(order.total.otherFees.value !== 0 ||
            order.total.discount.value !== 0) && (
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.value}>Subtotal:</Typography>
              <Typography className={classes.value}>
                {formatCurrency(
                  order.total.itemsPrice.currency,
                  order.total.itemsPrice.value
                )}
              </Typography>
            </Box>
          )}
          {order.total.discount.value !== 0 && (
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography className={classes.value}>
                Cupom aplicado: <br />
                <span style={{ color: "#DE4A82" }}>
                  {order?.discounts?.[0]?.targetId}
                </span>
              </Typography>
              <Typography
                className={classes.value}
                style={{ color: "#DE4A82", display: "flex", alignItems: "end" }}
              >
                {"- "}
                {formatCurrency(
                  order.total.discount.currency,
                  order.total.discount.value
                )}
              </Typography>
            </Box>
          )}
          {(order.total.otherFees.value !== 0 || order.type === "DELIVERY") && (
            <Box
              display="flex"
              justifyContent="space-between"
              mt={order.total.discount.value === 0 ? 0 : 1}
            >
              <Typography className={classes.value}>
                Taxa de entrega:
              </Typography>
              <Typography className={classes.value}>
                {order.total.otherFees.value !== 0
                  ? formatCurrency(
                      order.total.otherFees.currency,
                      order.total.otherFees.value
                    )
                  : "Grátis"}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.value}>Total:</Typography>
            <Typography className={classes.value}>
              {formatCurrency(
                order.total.orderAmount.currency,
                order.total.orderAmount.value
              )}
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography className={classes.label}>Forma de pagamento:</Typography>
          <Box display="flex">
            {/* <Box display="inline-block" mr={2}>
              <img src={logoAlelo} alt="logo Alelo" />
            </Box> */}
            <Typography className={classes.value}>
              {order.payments?.methods[0].method === "CREDIT" &&
                "Cartão de crédito"}
              {order.payments?.methods[0].method === "CREDIT_DEBIT" &&
                "Cartão de débito"}
              {order.payments?.methods[0].method === "OTHER" &&
                "Pagamento Offline"}
            </Typography>
          </Box>
          {order.payments?.methods[0].type === "PREPAID" ? (
            <AlertMessage
              title="Pedido já pago!"
              description="Não leve a maquininha."
              type="success"
            />
          ) : (
            <AlertMessage
              title="Necessário cobrança"
              description={
                order.payments?.methods[0].method === "OTHER"
                  ? "Se atente ao troco."
                  : "Leve a maquininha."
              }
              type="warning"
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
