import { useStyles } from "./login.styles";
import logo from "../../../assets/svgs/brand-pink.svg";
import { Box, Typography } from "@material-ui/core";
import LoginForm from "../components/login-form/login-form";
import withTemplate from "../../../styles/template/template";

function Login() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <img src={logo} alt="logo PedePronto" className={classes.logo} />
        <Typography className={classes.title}>
          Boas vindas ao Gestor de pedidos
        </Typography>
        <LoginForm />
      </Box>
    </Box>
  );
}

export default withTemplate(Login);
