import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#35007D", //@TODO: Change to theme
      fontWeight: 600,
      fontSize: "0.875rem",
      textTransform: "capitalize",
    },
  })
);
